<div class="container-fluid">
    <div class="iSomewhereHeader">
        <div class="cssLogo" style="position: absolute;margin-top:5px;right:5px;font-size:x-small;transform:scale(0.5)">
            <div class="logoFont">
                <div class="oneChar letter01">i</div>
                <div [(ngClass)]="addedClassS" class="oneChar letter02">S</div>
                <div class="oneChar letter03">o</div>
                <div class="oneChar letter04">m</div>
                <div class="oneChar letter05">e</div>
                <div [(ngClass)]="addedClassW" class="oneChar letter06">w</div>
                <div [(ngClass)]="addedClassH" class="oneChar letter07">h</div>
                <div class="oneChar letter08">e</div>
                <div class="oneChar letter09">r</div>
                <div class="oneChar letter10">e</div>
                <div class="oneChar dash">_</div>
            </div>
        </div>
        <div class="iSomewhereHeaderTitle">Export to Excel</div>
        <div class="iSomewhereHeaderSubtitle">A C# libray to export Lists, DataSets and DataTables to Excel
        </div>
    </div>
    <div class="container-fluid-wrapper">
        <div class="section">Introduction</div>
        <div class="notes">
            <p>In 2014, I was working for a finance company in Zurich, and decided to write a simple C# library to
                easily export
                data to an Excel file, and share it with the development community for free.</p>
            <p>
                With my library, and one line of C# code, a developer could export their data to a real Excel file, from
                a <code>List&lt;&gt;</code>,
                <code>DataSet</code> or <code>DataTable</code>.
            </p>
            <p>10 years later, and the response has been amazing.</p>
            <table class="tblReleases">
                <thead>
                    <tr>
                        <td>Views</td>
                        <td>Downloads</td>
                        <td>Link</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>498,200</td>
                        <td>19,300</td>
                        <td><a href="https://www.codeproject.com/Articles/692092/A-free-Export-to-Excel-Csharp-class-using-OpenXML#xx4715594xx" target="_blank">CodeProject:
                                A free "Export to Excel" C# class, using OpenXml</a></td>
                    </tr>
                    <tr>
                        <td>192,300</td>
                        <td>7,100</td>
                        <td><a href="https://www.codeproject.com/Articles/692121/Csharp-Export-data-to-Excel-using-OpenXML-librarie" target="_blank">CodeProject:
                                C# Export data to Excel, using OpenXML libraries
                            </a></td>
                    </tr>
                    <tr>
                        <td>-</td>
                        <td>3,400</td>
                        <td><a href="https://www.nuget.org/packages/Easy.ExportToExcel/" target="_blank">NuGet: Easy.ExportToExcel
                                package</a></td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td>690,500</td>
                        <td>20,800</td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>
            <p>It's now April 2024, and time to have an update !</p>
        </div>
        <div class="section">What it does</div>
        <div class="notes">
            <p>
                With one line of C# code, you can export your data from a <code>List&lt;&gt;</code>,
                <code>DataSet</code> or <code>DataTable</code> into a real Excel file, using the OpenXML libraries.
            </p>
            <p class="withTick">It's free</p>
            <p class="withTick">Incredibly easy to add to your C# projects</p>
            <p class="withTick">It creates <i>real</i> Excel files, with automatic formatting</p>
            <p class="withTick">No need to have Excel or Office installed on your server</p>
            <p class="withTick">Optimises memory usage, by using OpenXmlWriter, so can write <i>huge</i> Excel files</p>
            <p class="withTick">And yes, 10 years on, it still works with the latest .Net libraries</p>
            <p>
                But, let's be clear about this...
            </p>
            <p class="withCross">It only creates <i>basic</i> Excel files, of pure text/numeric data. No images, pivot tables, etc etc.
            </p>
            <p class="withCross">It only <u>creates</u> Excels, it cannot append/update data on an existing Excel</p>
            <p class="withCross">It only <u>writes</u> Excel files, there's no function to load Excel files</p>
            <p>
                It serves a very specific role, but is not meant to be a competitor to paid-for 3rd party libraries like 
                <a href="https://products.aspose.com/cells" target="_blank">Aspose</a>, 
                <a href="https://www.syncfusion.com/document-processing/excel-framework/net" target="_blank">Syncfusion</a>, 
                <a href="https://www.easyxls.com/manual/tutorials/csharp/export-dataset-to-excel.html" target="_blank">EasyXls</a>, etc.
            </p>
        </div>
        <div class="section">Installation</div>
        <div class="notes">
            <p>
                The easiest way to get this libary is to use GitHub.<br />
                Let's assume you're running Visual Studio 2022 with .Net 8, and you've created a new C# project
                of type "ASP.NET Core Web Api"<br />
            <p>
                At the time of writing, VS2022 would create a friendly WeatherForecast WebAPI project for you.<br />
                Let's get it to export some data to Excel.
            </p>
            <!-- https://mateam.net/html-escape-characters/  -->
            <ul>
                <li>Click on:
                    <strong>Tools\NuGet Package Manager\Manage NuGet Packages for Solution...</strong>
                </li>
                <li>Click on the "Browse" tab, search for "<strong>Easy.ExportToExcel</strong>", and install this into your project</li>
                <li>Delete the <i>WeatherForecastController.cs</i> controller.  I'm English, and I already know that the weather will be miserable.</li>
                <li>Right-click on Controllers, and click on <strong>Add\Controller</strong> and create an "MVC Controller - Empty"</li>
                <li>Click on Add, and give it a filename of <i>CustomersController.cs</i></li>
                <li>Replace the code with this:</li>
<div class="cssCodeSegment">
<pre>
using ExportToExcel;
using Microsoft.AspNetCore.Mvc;
using System.Data;
using System.Net;
namespace ExportTest.Controllers
&#123;
    &#91;ApiController&#93;
    &#91;Route("&#91;controller]")&#93;
    public class CustomersController : ControllerBase
    &#123;
        &#91;HttpGet&#93;
        public IActionResult Get()
        &#123;
            var filename = "Customers.xlsx";
            var contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

            //  Create some sample data
            var customers = CreateSampleData();

            //  Get the ExportToExcel library to save it into a local Excel file
            var pathFilename = Path.Join(Path.GetTempPath(), filename);
            <span style="color:red">CreateExcelFile.CreateExcelDocument(customers, pathFilename);</span>

            //  Return the raw Excel file 
            var fileBytes = System.IO.File.ReadAllBytes(pathFilename);
            return File(fileBytes, contentType, filename);
        &#125;

        private List&lt;Customer&gt; CreateSampleData()
        &#123;
            var customers = new List&lt;Customer&gt;();

            customers.Add(new Customer("Bob", "Jones", "13 Somewhere Street", "Bristol", "BS1 3AA", "Great Britain",
                "0117 110 1010", new DateTime(1997, 3, 27), 1.78, 74.2));

            customers.Add(new Customer("Frank", "Parsons", "100 Exporting Road", "Swansea", "SN3 4ZD", "Great Britain",
                "01792 100 2030", new DateTime(1985,11, 30), 1.84, 82.3));

            customers.Add(new Customer("Sandra", "Mitchell", "43 Elsewhere Road", "Bath", "BA1 9TT", "Great Britain",
                "01225 230 4943", new DateTime(1991, 12, 3), 1.71, 75.3));
            return customers;
            &#125;
        &#125;
    &#125;
        
    public class Customer
    &#123;
        public Customer(string first, string last, string addr, string city, string post, string country, string phone, DateTime dob, double height, double weight)
        &#123;
            FirstName = first;
            LastName = last;
            Address = addr; 
            City = city;   
            PostalCode = post;
            Country = country;
            Phone = phone;
            DateOfBirth = dob;
            HeightMeter = height;
            WeightKg = weight;
        &#125;
        public string FirstName &#123; get; set; &#125;    
        public string LastName &#123; get; set; &#125; 
        public string Address &#123; get; set; &#125;
        public string City &#123; get; set; &#125;
        public string PostalCode &#123; get; set; &#125;
        public string Country &#123; get; set; &#125;
        public string Phone &#123; get; set; &#125;
        public DateTime DateOfBirth &#123; get; set; &#125;
        public double HeightMeter &#123; get; set; &#125;
        public double WeightKg &#123; get; set; &#125;
    &#125;
</pre>
</div>
            </ul>
            <p>And that's it.<br />
            Most of this code is just to create sample data and return it in the correct format, but all of the Excel work
            is done in that one line of code in red.</p>
            <img src="../../../assets/ExportedExcel.png" />
            <p>When I say that this is a "real" Excel file, I really mean that.<br />
            <ul>
                <li>The columns are based on the types in your Model, so doubles are shown with 2 decimal places, etc</li>
                <li>The "Date of Birth" field is an Excel date field (Excel stores dates as integers, and allows the 
                    user to change the format)</li>
                <li>The column heading are based on your Model's field names.  <br />
                    If you export from a DataSet or DataTable, you can set the column headers in the <code>DataColumn.ColumnName</code> field.</li>
            </ul>
        </div>
    </div>
</div>
