import { Component, OnInit, Directive } from '@angular/core';
import { Router } from '@angular/router';
import { default as dict } from "../../../assets/dictionary.json";

@Component({
  selector: 'app-wordzee-solver',
  templateUrl: './wordzee-solver.component.html',
  styleUrls: ['./wordzee-solver.component.scss']
})
export class WordzeeSolverComponent implements OnInit {

  constructor(private router: Router) { }

  searchText = 'BEEDEST';
  bSimpleMode = true;
  results = '';
  numOfResults = 0;

  ngOnInit(): void {
    setTimeout(() => {
      this.search();
  }, 500);
  }

  onTextChange(event) {
      this.searchText = event.toUpperCase();
      if (this.searchText.length == 7) {
          this.search();
      } else {
          this.results = '';
      }
  }

  countUnique(str) {
    //  How many unique characters are there in this string ?
    return new Set(str).size;
  }

  hasEachLetterOnce(str) {
      //  Does a particular string ONLY contain each of our "search-string" letters once ?
      //  eg //  BEEDEST => "STEEDS", "TDD", etc   
      let lettersInThisString = str.split('').sort();
      let lettersInSearchString = this.searchText.split('').sort();
      let bPassed = true;

      lettersInThisString.forEach(letter => {
          let inx = lettersInSearchString.indexOf(letter);
          if (inx == -1) {
              bPassed = false;
          } else {
              lettersInSearchString.splice(inx, 1);
          }
      });
      return bPassed;
  }

  search() {
    if (this.searchText.length != 7) {
        return;
    }

    let lettersInSearchString = this.searchText.split('').sort();

    //  Get a list of dictionary words which are X characters and longer
    let allWords = dict.dict.filter(word => word.length >= 3 && word.length <= 7);

    //  Get a list of dictionary words which are X characters and longer
    allWords = allWords.filter(word => word.length >= 3 && word.length <= 7);

    //  Go through the array, discarding words whose n'th character isn't one of the
    //  characters in our search string
    //  "SEEDXXX"
    //  Discard any words which contain letters that aren't in our 7-letter string
    for (var i = 0; i < 30; i++) {
        allWords = allWords.filter(s => s.length <= i || lettersInSearchString.includes(s[i]));
    }

    //  Sort our list of words, to have the longest words first
    allWords.sort((a, b) => {
        return b.length - a.length;
    })
    
    //  BEEDEST => "STEEDS", "TDD", etc   
    //  Can only use each letter once

    allWords = allWords.filter(s => this.hasEachLetterOnce(s));

    this.numOfResults = allWords.length;

    this.results = allWords.join(', ');
  }

  onHome() {
    setTimeout(() => {
      this.router.navigate(['/']);
    }, 1000);
  }
  
  onChangeSimpleMode() {

  }
}
