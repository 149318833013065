<div class="cssScreenSize">
</div>
<div class="cssBackgroundGradient" style="display: flex;flex-flow: column;height:100%;">
    <div class="headerHeight" style="height:140px;text-align:center;padding-top:40px;">
        <!--  Header bar -->
        <img src="../../../assets/ZurichTogether.png" width="230">
    </div>
    <div style="flex-direction:column;border:1px dotted green;padding:5px;height:300px;">
        <!-- <ngx-dropzone (change)="onDragAndDropped($event)" style="flex-direction:column;width:100%;height:300px;border:1px dotted blue;">
            <div *ngFor="let fil of selectedContainer?.listOfFiles" class="oneFile" (click)="downloadFile($event, fil)">
                {{ fil }}
            </div>
        </ngx-dropzone>  -->
        <ngx-file-drop dropZoneLabel="Drop files here" contentClassName="cssContentZone" dropZoneClassName="cssDropZone" *ngIf="files.length == 0"
            (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                Optional custom content that replaces the the entire default content.
                <button type="button" (click)="openFileSelector()">Browse Files</button>
            </ng-template>
        </ngx-file-drop>
        <div class="upload-table" *ngIf="files.length > 0">
            <table class="table">
                <thead>
                    <tr>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody class="upload-name-style">
                    <tr *ngFor="let item of files; let i=index">
                        <td><strong>{{ item.relativePath }}</strong></td>
                    </tr>
                </tbody>
            </table>
        </div> 

    </div>
</div>