import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PangramsSolverComponent } from './components/pangrams-solver/pangrams-solver.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FormsModule } from '@angular/forms';
import { WordzeeSolverComponent } from './components/wordzee-solver/wordzee-solver.component';
import { WordleSolverComponent } from './components/wordle-solver/wordle-solver.component';
import { HomeComponent } from './components/home/home.component';
import { WordleGeneratorComponent } from './components/wordle-generator/wordle-generator.component';
import { MathsBlockComponent } from './components/maths-block/maths-block.component';
import { FillTheBlankComponent } from './components/fill-the-blank/fill-the-blank.component';
import { WatermarkComponent } from './components/watermark/watermark.component';
import { DropzoneCdkModule } from '@ngx-dropzone/cdk';
import { NgxFileDropModule } from 'ngx-file-drop';
import { HttpClientModule } from '@angular/common/http';
import { ExportToExcelComponent } from './components/export-to-excel/export-to-excel.component';

@NgModule({
  declarations: [
    AppComponent,
    PangramsSolverComponent,
    WordzeeSolverComponent,
    WordleSolverComponent,
    HomeComponent,
    FillTheBlankComponent,
    MathsBlockComponent,
    WordleGeneratorComponent,
    WatermarkComponent,
    ExportToExcelComponent
  ],
  imports: [
    BrowserModule,
    AlertModule.forRoot(), 
    TooltipModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    DropzoneCdkModule,
    FormsModule,
    HttpClientModule,
    NgxFileDropModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
