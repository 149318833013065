import { AfterViewInit, Component, HostListener, OnInit, ViewChild, Directive } from '@angular/core';
import { default as dict } from "../../../assets/fiveLetterWords.json";
// import { dict } from "../../../assets/dictionary.json";
import { ElementRef } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-wordle-generator',
  templateUrl: './wordle-generator.component.html',
  styleUrls: ['./wordle-generator.component.scss']
})
export class WordleGeneratorComponent implements OnInit, AfterViewInit  {

  readonly DEBUG_MODE = false;

  constructor(private router: Router) { }
  @ViewChild("gridArea", { static: false }) gridArea: ElementRef;

  @HostListener('window:resize', ['$event'])
    onResizeScreen(event) {
        this.onScreenResize();
    }
  @HostListener('window:load', ['$event'])
    onLoadScreen(event) {
        this.onScreenResize();
    }

  ngAfterViewInit() {
    this.onScreenResize();

    // var runs = 0;
    // var cookieValue = this.cookieService.get('NumberOfRuns');
    // if (cookieValue != null) {
    //     runs = +cookieValue;
    // }
    // runs++;
    // this.cookieService.set('NumberOfRuns', runs.toString());

  }

  readonly WORD_MUST_EXIST = true;
  bShowHelp = true;
  helpPageNumber = 1;
  whichHelpScreen = 'showPage1';
  screenSize= '';
  cellWidth = '40px';
  cellWidthSolution = '40px';
  fontSize = '20px';
  fontSizeSolution = '20px';
  lineHeight = '36px';
  lineHeightSolution = '36px';
  wordToGuess= '';
  wordleText='';
  sixGuesses = new Array<string>();
  guessNumber = 0;
  MAX_GUESSES = 6;
  MAX_WORD_LENGTH = 5;
  currentGuess = '';
  bGameOver = false;
  bHaveWon = false;
  bHaveGuessedCorrectly = false;
  bShakeLetters = false;
  bInvalidWord = false;
  letters = [['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'], 
    ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'], 
    ['Z', 'X', 'C', 'V', 'B', 'N', 'M']];
  cssLetters = new Array<string>();
  @ViewChild('setFocusHere') vc: ElementRef;

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
      if (event.key === 'Backspace') {
          if (this.sixGuesses[this.guessNumber].length > 0) {
            this.sixGuesses[this.guessNumber] = this.sixGuesses[this.guessNumber].slice(0, -1);
          }
          return;
      }
      if (event.key === 'Enter') {
          this.onPressEnter();
          return;
      }
      if (event.key.length != 1 || event.key.toUpperCase() < 'A' || event.key.toUpperCase() > 'Z') {
          console.log('Pressed "' + event.key + '"');
          return;          
      }
      this.onClickedLetter(event.key.toUpperCase());
  }

  onScreenResize() {
      //  Need to put this in a setTimeout, otherwise we get the exception about a value being changed
      //  during initialisation
      setTimeout(() => {
            let wid = this.gridArea.nativeElement.offsetWidth;
            let hei = this.gridArea.nativeElement.offsetHeight;
            let BUFFER = 5;
            let BUFFER_HEIGHT = 14;
            let smallness = 0.5;
            wid -= 4 * BUFFER;       //  There are 4 column gaps
            hei -= 5 * BUFFER_HEIGHT;       //  There are 5 row gaps
            let cellSize = ((wid > hei) ? hei / 6 : wid / 6);
            this.cellWidth = cellSize + 'px';
            this.cellWidthSolution = (cellSize *  smallness) + 'px';
            this.lineHeight = (cellSize*0.95) + 'px';
            this.lineHeightSolution = (cellSize * 0.95 * smallness) + 'px';
            this.fontSize = (cellSize*0.5) + 'px';
            this.fontSizeSolution = (cellSize * 0.5 * smallness) + 'px';
    //        this.screenSize = wid + ", " + hei + ' : width: ' + this.cellWidth;
            this.screenSize = this.gridArea.nativeElement.offsetWidth + ', ' + this.gridArea.nativeElement.offsetHeight; 
      }, 500);
    }
 
  onClickedLetter(letter) {
    switch (letter) {
      case 'Delete':
          if (this.sixGuesses[this.guessNumber].length > 0) {
              this.sixGuesses[this.guessNumber] = this.sixGuesses[this.guessNumber].slice(0, -1);
          }
          break;
      case 'Enter':
          this.onPressEnter();
          break;
      default:
          if (this.sixGuesses[this.guessNumber].length == this.MAX_WORD_LENGTH) {
              //  They've already typed in 5 chars... now allowed to type in any more !
              return;
          }
          this.sixGuesses[this.guessNumber] += letter;

          var currentWord = this.sixGuesses[this.guessNumber];
          if (currentWord.length == this.MAX_WORD_LENGTH) {
              if (this.WORD_MUST_EXIST && dict.dict.indexOf(currentWord) == -1) {
                this.bShakeLetters = true;
                setTimeout(() => {
                    this.bShakeLetters = false;
                }, 500);
                return;
            }
          }

          break;
      }
  }

  haveGuessedLetter(letter) {
      //  What CSS class should we apply to  each of the keys on our on-screen keyboard
      switch (letter) {
          case 'Delete':
              return 'cssDelete';
          case 'Enter':
              return 'cssEnter';
          default:
              return this.cssLetters[letter.charCodeAt()-65];
      }
      
  } 

  ngOnInit(): void {
    this.startGame();
  }
  startGame() {
    //  Get a list of dictionary words which are X characters and longer
    let allWords = dict.dict.filter(word => word.length == 5);

    //  Ignore words containing an apostrophe
    allWords = allWords.filter(word => word.indexOf('\'') == -1);
    allWords = allWords.filter(word => word.indexOf('.') == -1);

    //  Remove words like "GLACÉ", "SEÑOR", etc
    allWords = allWords.filter(word => word.match(/^[a-zA-Z]*$/));

    // var str = '{ "dict": ['; 
    // allWords.forEach(word => str += '"' + word + '",\r\n');
    // str += '] }';
    // console.log(str);

    this.wordToGuess = allWords[Math.floor(Math.random() * allWords.length)];

//  console.log('Word to guess: ' + this.wordToGuess);

    this.sixGuesses = new Array<string>(this.MAX_GUESSES);
    this.cssLetters = new Array<string>(26);
    for (let i=0; i<26; i++){
      this.cssLetters[i] = 'cssCharacterNotTried';
    }

    this.guessNumber = 0;
    for (let i=0; i<this.MAX_GUESSES; i++){
        this.sixGuesses[i] = '';
    }

    this.bGameOver = false;
    this.bHaveWon = false;
    this.bHaveGuessedCorrectly = false;

 //   debugger;
    if (this.DEBUG_MODE) {
        this.bGameOver = true;
        this.bHaveWon = false;
        this.bShowHelp = false;
    }
  }
  
  closeHelp() {
      this.bShowHelp = false;
  }
  onShowHelp() {
      this.bShowHelp = true;
      this.helpPageNumber = 1;
      this.whichHelpScreen = "showPage" + this.helpPageNumber;
  }
  prevHelpPage() {
      this.helpPageNumber = (this.helpPageNumber == 1) ? 6 : (this.helpPageNumber-1);
      this.whichHelpScreen = "showPage" + this.helpPageNumber;
  }
  nextHelpPage() {
      this.helpPageNumber = (this.helpPageNumber == 6) ? 1 : (this.helpPageNumber+1);
      this.whichHelpScreen = "showPage" + this.helpPageNumber;
  }

  getLetterFromGuess(guessWord, inx) {
      if (guessWord == null || guessWord == '' || guessWord.length <= inx)
        return '';

      return guessWord[inx];
  }

  onPressEnter() {
      if (this.sixGuesses[this.guessNumber].length != this.MAX_WORD_LENGTH) {
          return;
      }
      if (this.sixGuesses[this.guessNumber] == this.wordToGuess) {
          this.bHaveGuessedCorrectly = true;

          //  Wait for the 5-winning-letters to do their animation, then show the "Well done!" popup
          setTimeout(() => {
              this.bHaveWon = true;
          }, 3500);
          return;
      }
      //  You're only allowed to submit valid English words.
      if (this.WORD_MUST_EXIST && dict.dict.indexOf(this.sixGuesses[this.guessNumber]) == -1) {
          this.bShakeLetters = true;
          setTimeout(() => {
              this.bShakeLetters = false;
          }, 1000);
          return;
      }

      this.guessNumber++;
      if (this.guessNumber == this.MAX_GUESSES) {
          setTimeout(() => {
              this.bGameOver = true;
          }, 2000);
          return;
      }
  }

  onSurrender() {
    setTimeout(() => {
      this.bGameOver = true;
    }, 1000);
  }
  onHome() {
    setTimeout(() => {
      this.router.navigate(['/']);
    }, 1000);
  }

  isLetterInSolution(inx) {
      //  When the game is over, this will show the 5-letter word in the relevant CSS classes 
      if (this.bHaveGuessedCorrectly)
          return 'cssWasCorrectGuess';

      console.log('isLetterInSolution(' + inx + ') : ' + this.haveGuessedLetter(this.wordToGuess[inx]));
      return this.haveGuessedLetter(this.wordToGuess[inx]);
  }
  isLetterInWord(guessInx, word, charInx) {
      //  This is called by the .html to work out which CSS class to attach to a character's <div> box
      if (this.bInvalidWord) {
        return 'cssInvalidWord';
      }
      if (guessInx == this.guessNumber) {
          if (this.bShakeLetters) {
              //  They've entered an invalid 5-letter word
              return 'cssInvalidWord';  //  'cssShakeLetters';
          }
          if (charInx == this.sixGuesses[this.guessNumber].length)  {
//            console.log('Setting cursor on guess# ' + guessInx + ', char: ' + charInx);
              return this.bGameOver ? '' : 'cssCursorPos';
          }
          if (word == this.wordToGuess && this.bHaveGuessedCorrectly) {
              //  When they guess the word correctly, we'll make the tiles pulse, in a rhythm
              return 'cssGuessedCorrectly cssPause' + charInx;
          }
          return '';
      }
      //  Find the n'th character in our current guess 
      var character = this.getLetterFromGuess(word, charInx);
      if (character == null || character == '' || character == '') {
          return '';
      }
      var CSSclass = '';

      //  If the word is "RORKE" and we guessed STRAP, we want the CSS to show that R is in the correct
      //  place *and* also in a different place - unless we have correctly identified all the places 
      //  where an "R" should go
      if (this.wordToGuess[charInx] == character) {
          CSSclass = 'cssCharacterInCorrectPlace'; 
          for (let inx=0; inx<this.MAX_WORD_LENGTH; inx++) {
              if (this.wordToGuess[inx] == character && word[inx] != character)
                  CSSclass = 'cssCharacterInCorrectPlaceAndElsewhere'; 
          }
      } else {
          var positionInWord = this.wordToGuess.indexOf(character);
          switch (positionInWord) {
              case -1: 
                  CSSclass = 'cssCharacterNotInWord'; 
                  break;
              case charInx: 
                  CSSclass = 'cssCharacterInCorrectPlace'; 
                  break;
              default: 
                  CSSclass = 'cssCharacterInWrongPlace'; 
                  break;
          }
      }
      this.cssLetters[character.charCodeAt()-65] = CSSclass;
      return CSSclass;
  }
}
