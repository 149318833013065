import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PangramsSolverComponent } from './components/pangrams-solver/pangrams-solver.component';
import { WordzeeSolverComponent } from './components/wordzee-solver/wordzee-solver.component';
import { WordleGeneratorComponent } from './components/wordle-generator/wordle-generator.component';
import { HomeComponent } from './components/home/home.component';
import { MathsBlockComponent } from './components/maths-block/maths-block.component';
import { FillTheBlankComponent } from './components/fill-the-blank/fill-the-blank.component';
import { WatermarkComponent } from './components/watermark/watermark.component';
import { ExportToExcelComponent } from './components/export-to-excel/export-to-excel.component';

const routes: Routes = [
    { path: 'pangrams', component: PangramsSolverComponent },
    { path: 'wordzee', component: WordzeeSolverComponent },
//  { path: 'wordle', component: WordleSolverComponent },
    { path: 'wordle', component: WordleGeneratorComponent },
    { path: 'wordie', component: WordleGeneratorComponent },
    { path: 'maths', component: MathsBlockComponent },
    { path: 'watermark', component: WatermarkComponent },
    { path: 'fill', component: FillTheBlankComponent },
    { path: 'exportToExcel', component: ExportToExcelComponent },
    { path: '**', component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
