
<div class="container">
    <div class="row">
        <div class="offset-md-2 col-md-8">
            <h4>Pangrams Solver</h4>
        </div>
    </div>
    <div style="height:10px" ></div>
    <div class="row">
        <div class="offset-md-2 col-md-2">
            <label for="tbPangram">Text:</label>
        </div>
        <div class="col-md-4">
            <input type="text" id="tbPangram" class="form-control" [(ngModel)]="pangramText" (ngModelChange)="onTextChange($event)">
        </div>
    </div>
    <div class="row">
        <div class="offset-md-4 col-md-8" *ngIf="error != ''">
            {{ error }}
        </div>
    </div>
    <div class="row">
        <div class="offset-md-4 col-md-4" style="padding:20px 12px">
            <svg height="200" width="400">
                <g *ngFor="let hexagon of hexagons; index as i">
                    {{hexagon.background}}
                    <polygon 
                        [attr.points]="getPoints(hexagon)" 
                        [attr.class]="hexagon.background" >
                    </polygon>
                    <text [attr.x]="hexagon.x+32"  
                        [attr.y]="hexagon.y+39"
                        width="30" height="50"
                        [attr.class]="hexagon.text"
                        dominant-baseline="middle" 
                        [attr.fill]="'#777'"
                        text-anchor="middle">
                        {{ pangramText[i] }}
                    </text>
                </g>
            </svg> 
        </div>
    </div>
    <div class="row" style="min-height:35px;">
        <div class="offset-md-2 col-md-2">
            Main result:
        </div>
        <div class="col-md-4 mainAnswer">
            {{ mainResult }}
        </div>
    </div>
    <div class="row" style="min-height:150px;">
        <div class="offset-md-2 col-md-2">
            Results:
            <div *ngIf="numOfMatches > 0">({{ numOfMatches }})</div>
        </div>
        <div class="col-md-6">
            {{ results }}
        </div>
    </div>

    <div class="row">
        <div class="offset-md-4 col-md-2">
            <button class="btn-primary btn-sm" (click)="onGenerate()">Generate</button>
        </div>
    </div>

</div>

