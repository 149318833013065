<div class="cssFloatLeft button" (click)="onHome()" title="Home" style="z-index:5000" >
    <img src="../../../assets/Home.png"  >
</div>
<div class="container">
    <div class="row">
        <div class="offset-md-2 col-md-8">
            <h4>Wordzee Solver</h4>
        </div>
    </div>
    <div style="height:10px" ></div>
    <div class="row">
        <div class="offset-md-2 col-md-2">
            <label for="tbSearchText">Text:</label>
        </div>
        <div class="col-md-4">
            <input type="search" id="tbSearchText" class="form-control" [(ngModel)]="searchText" (ngModelChange)="onTextChange($event)">
        </div>
    </div>
    <div class="row" style="height:35px">
        <div class="offset-md-2 col-md-2">
            <label for="tbPangram" >Simple mode</label>
        </div>
        <div class="col-md-4">
            <input style="width:20px;transform: scale(1.6);" type="checkbox" id="tbSimpleMode" name="tbSimpleMode" [(ngModel)]="bSimpleMode" (ngModelChange)="onChangeSimpleMode()">
        </div>
    </div>

    <div class="row">
        <div class="offset-md-2 col-md-2">
            <label for="tbPangram">Results</label> ({{ numOfResults }})
        </div>
        <div class="col-md-4">
            {{ results }}
        </div>
    </div>

</div>
