import { Component, OnInit, Directive } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AnonymousCredential, BlobServiceClient } from '@azure/storage-blob';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';

@Component({
  selector: 'app-watermark',
  templateUrl: './watermark.component.html',
  styleUrls: ['./watermark.component.scss']
})
export class WatermarkComponent implements OnInit {

  constructor() { }

  message = '';
  listOfContainers = new Array<OneContainer>();
  selectedContainer = null;

  ngOnInit(): void {
  }

  //  https://www.npmjs.com/package/ngx-file-drop
  
  public files: NgxFileDropEntry[] = [];

  public dropped(files: NgxFileDropEntry[]) {
    debugger;
    this.files = files;
    for (const droppedFile of files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          // Here you can access the real file
          console.log(droppedFile.relativePath, file);

          /**
          // You could upload it like this:
          const formData = new FormData()
          formData.append('logo', file, relativePath)

          // Headers
          const headers = new HttpHeaders({
            'security-token': 'mytoken'
          })

          this.http.post('https://mybackend.com/api/upload/sanitize-and-save-logo', formData, { headers: headers, responseType: 'blob' })
          .subscribe(data => {
            // Sanitized logo returned from backend
          })
          **/

        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  public fileOver(event){
    console.log(event);
  }

  public fileLeave(event){
    console.log(event);
  }


  //  npm i --save ngx-dropzone
  //  npm i @azure/storage-blob

  getBlobServiceClient() {
    // Use AnonymousCredential when url already includes a SAS signature
    const anonymousCredential = new AnonymousCredential();

    // List containers
    const blobServiceClient = new BlobServiceClient(
        // When using AnonymousCredential, following url should include a valid SAS or support public access
        `https://${environment.accountName}.blob.core.windows.net/${environment.key}`, anonymousCredential
    );

    return blobServiceClient;
  }

  onDragAndDropped = async(event) => {
    //  The user has just drag'n'dropped one or more files into our webpage
    let arrayOfFiles = event.addedFiles;

    if (arrayOfFiles == null || arrayOfFiles.length == 0)  {
        return;
    }

    const blobServiceClient = this.getBlobServiceClient();

/*
      // Create a new container
      const containerName = `container-${this.getDateAsString()}`;
      const containerClient = blobServiArra.ceClient.getContainerClient(containerName);
      const createContainerResponse = await containerClient.create();
      console.log(`Create container \"${containerName}\" successfully.`, createContainerResponse.requestId);
*/

      this.message = 'Uploading ' + arrayOfFiles.length + ' files.';

      const containerClient = blobServiceClient.getContainerClient(this.selectedContainer.name);

      const promises = [];
      let inx = 0;
      Array.from(arrayOfFiles).forEach(oneFile => {
          try { 
            let fileToUpload = <File>oneFile;
            this.message = 'Uploading: ' + fileToUpload.name;

            this.message += fileToUpload.name + "  ";
            const blockBlobClient = containerClient.getBlockBlobClient(fileToUpload.name); //  zzz
            promises.push(blockBlobClient.uploadBrowserData(fileToUpload));
            const response = Promise.all(promises); 
            this.selectedContainer.listOfFiles.push(fileToUpload.name);
            console.log(`${++inx} : Successfully uploaded: \"${fileToUpload.name}\".`);
          }
          catch (error) {
            console.log('An error occured: ' + error.message);
            this.message = error.message;
          }
        });
        this.message = `Successfully uploaded ${inx} files.`;
      
        // let newCon:OneContainer = {
        //     name: this.selectedContainer.name,
        //     listOfFiles: new Array<string>()
        // };
        // this.listOfContainers.push(newCon);
  }
}

export class OneContainer {
  name: string;
  listOfFiles = new Array<string>();
}


