import { Component, OnInit, Directive } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private router:Router) { }
  addedClassS = '';
  addedClassW = '';
  addedClassH = '';
  textW = 'w';
  
  ngOnInit(): void {
    setInterval (() => {
      this.addedClassS = 'cssBlip';

      setTimeout  (() => {
        this.addedClassS = '';
      }, 100);
    }, 3000);

    setInterval (() => {
      this.addedClassH = 'cssBlip2';

      setTimeout  (() => {
        this.addedClassH = '';
      }, 150);
    }, 6000);

    setInterval (() => {
      this.addedClassW = 'cssBlip3';

      setTimeout  (() => {
        this.addedClassW = '';
      }, 100);
    }, 4000); 
  }
  onJumpToRoute(url) {
    setTimeout(() => {
        this.router.navigate([url]);
    }, 1000);
  }
  onShowWordzee() {
    this.router.navigate(['/wordzee']);
  }
  onShowPangrams() {
    this.router.navigate(['/pangrams']);
  }
  onShowWordle() {
    this.router.navigate(['/wordie']);
  }
  onShowWordleGenerator() {
    this.router.navigate(['/wordie']);
  }



}
