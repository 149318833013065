import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-export-to-excel',
  templateUrl: './export-to-excel.component.html',
  styleUrls: ['./export-to-excel.component.scss']
})
export class ExportToExcelComponent implements OnInit {

  addedClassS = '';
  addedClassW = '';
  addedClassH = '';
  textW = 'w';

  constructor(private titleService:Title) {
    this.titleService.setTitle("iSomewhere - Export to Excel");
  }

  ngOnInit(): void {
    setInterval (() => {
      this.addedClassS = 'cssBlip';

      setTimeout  (() => {
        this.addedClassS = '';
      }, 100);
    }, 3000);

    setInterval (() => {
      this.addedClassH = 'cssBlip2';

      setTimeout  (() => {
        this.addedClassH = '';
      }, 150);
    }, 6000);

    setInterval (() => {
      this.addedClassW = 'cssBlip3';

      setTimeout  (() => {
        this.addedClassW = '';
      }, 100);
    }, 4000); 
  }


}
