
<div class="pageBackground" >
    <img style="margin:0px 0px 15px 0px;" src="../../../assets/MathsBlock.png" width="350" >
    <div class="equationRow">
        <div *ngFor="let element of componentsOfEquations; index as i" style="display:inline-block" class="oneColumn">
            <!-- <div class="aboveBlock" *ngIf="!blockIsLocked[i]"
                [ngClass]="{ 'blockNumber': (i%2)==0, 'blockOperator': (i%2)!=0}" >X
            </div> -->
            <div class="oneBlock" 
                [ngClass]="{ 'blockNumber': (i%2)==0, 'blockOperator': (i%2)!=0, 'blockLocked': blockIsLocked[i]}" >
                <div *ngIf="blockIsLocked[i]">{{ element }}</div>
            </div>
            <!-- <div class="belowBlock" *ngIf="!blockIsLocked[i]"
                [ngClass]="{ 'blockNumber': (i%2)==0, 'blockOperator': (i%2)!=0}" >X
            </div> -->
        </div>
        <div class="oneColumn">
            <div class="oneBlock blockEquals ">=</div>
        </div>
        <div class="oneColumn">
            <div class="oneBlock blockResult oneColumn">{{ equationResult }}</div>
        </div>
    </div>
    <div *ngIf="bShowAnswer" class="answer">
        <div *ngFor="let element of componentsOfEquations; index as i" style="display:inline-block" class="oneColumn">
            <div [ngClass]="{ 'answerBlock': !blockIsLocked[i]}">
                {{ element }} 
            </div>
        </div>
        = {{ this.equationResult }}
    </div>
    <button class="btn btn-primary" (click)="chooseRandomEquation()">Generate</button>&nbsp;
    <button class="btn btn-secondary" (click)="showAnswer()">Show Answer</button>
</div>

<div *ngIf="error != ''">
    <h4>{{ error }}</h4>
</div>