
<div class="container">
    <div class="row">
        <div class="offset-md-2 col-md-8">
            <h4>Wordle Solver</h4>
        </div>
    </div>
    <div style="height:10px" ></div>
    <div class="row">
        <div class="offset-md-2 col-md-2">
            <label for="tbWordle">Text:</label>
        </div>
        <div class="col-md-4">
            <input type="text" id="tbWordle" class="form-control" [(ngModel)]="wordleText" (ngModelChange)="onTextChange($event)">
        </div>
    </div>
</div>

