import { AfterViewInit, Component, HostListener, OnInit, ViewChild, Directive } from '@angular/core';
import { dict } from "../../../assets/fiveLetterWords.json";
// import { dict } from "../../../assets/dictionary.json";
import { ElementRef } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-maths-block',
  templateUrl: './maths-block.component.html',
  styleUrls: ['./maths-block.component.scss']
})
export class MathsBlockComponent implements OnInit  {

  constructor(private router: Router) { }

  componentsOfEquations: Array<string>;
  blockIsLocked: Array<boolean>;
  runningTotal: Array<number>;
  equationResult = 0.0;
  equation = '';
  error = '';
  bShowAnswer = false;

  MAXIMUM_NUMBER = 10;
  NUMBER_OF_LOCKED_BLOCKS = 2;
  MAX_RETRIES = 3000;

  levelNumber = 1;

  ngOnInit(): void {
    this.chooseRandomEquation();
  }
  elementsInEquation() {
      if (this.levelNumber < 10) 
          return 7;

      if (this.levelNumber < 20) 
          return 7;

      return 9;
  }

  factorsOfNumber(num) {
      //  Attempt to find a random factor of a number (eg  12 => 3, 4 or 6,  20 => 4 or 5,  16 => 2, 4, 8 )
      for(let i = 2; i <= num-2; i++) {
          // check if number is a factor
          if(num % i == 0) {
              return (this.random(2) == 1) ? (num / i) : i;
          }
      }
      return null;
  }

  chooseRandomEquation() {
      //  For level 1, we need an equation like "2 * 5 + 3 = 13"
      this.componentsOfEquations = new Array<string>(this.elementsInEquation());
      this.runningTotal = new Array<number>(this.elementsInEquation());
      this.blockIsLocked = new Array<boolean>(this.elementsInEquation());
      this.bShowAnswer = false;

      for (let inx=0; inx<this.componentsOfEquations.length; inx++) {
          this.blockIsLocked[inx] = true;
      }

      let bValidEquation = true;
      let retries = 0;
      do {
          for (let inx=0; inx<this.componentsOfEquations.length; inx+=2) {
              this.componentsOfEquations[inx] = (1+this.random(this.MAXIMUM_NUMBER)).toString();
          }
          for (let inx=1; inx<this.componentsOfEquations.length-1; inx+=2) {1
              this.componentsOfEquations[inx] = operatorType[this.random(3)];        //  Just +, - or *  (we'll deal with divide separately)
          }

        //   this.componentsOfEquations[0] = '5';
        //   this.componentsOfEquations[1] = 'x';
        //   this.componentsOfEquations[2] = '2';
        //   this.componentsOfEquations[3] = '-';
        //   this.componentsOfEquations[4] = '5';
        //   this.componentsOfEquations[5] = 'x';
        //   this.componentsOfEquations[6] = '4';
 //debugger;
        //  What is the result of this equation ?
        //  Problems:  
        //          16 + 20 - 17 ÷ 10 = 36     huh ?!
        //          8 ÷ 29 x 24 + 20 = 0.27586206896551724
        //          14 - 25 + 18 x 13 = -11
        //          6 - 1 + 10 - 7 = 5
        //
        this.runningTotal[0] = +this.componentsOfEquations[0];

        if (this.random(4) == 2) {
            //  There's a 1-in-4 chance of us spltting a division in the middle of this equation! 
            this.splatDivisionInArray();
        }
        bValidEquation = this.calculateResult(retries);

        console.log(JSON.stringify(this.runningTotal));

        retries ++;

    } while (!bValidEquation && retries < this.MAX_RETRIES);
    
    this.equation = this.componentsOfEquations.join(' ') + ' = ' + this.equationResult;

    this.error = '';
    if (retries >= this.MAX_RETRIES) {
        this.error = 'Maximum retries reached: ' + retries;
        console.log(this.error + ', bValidEquation=' + bValidEquation + ', equation: ' + this.equation);
    } else {
        console.log('Found a value equation: ' + this.equation + ', bValidEquation=' + bValidEquation);
    }
  
    for (let inx=0; inx<this.NUMBER_OF_LOCKED_BLOCKS; inx++) {
        this.blockIsLocked[1+this.random(this.componentsOfEquations.length-2)] = false;
    }
  }

  calculateResult(retries) {
    this.equationResult = +this.componentsOfEquations[0];
    for (let inx=1; inx<this.componentsOfEquations.length-1; inx+=2) { 

        if (this.equationResult == 1 && this.componentsOfEquations[inx] == 'x')
            return false;

        switch (this.componentsOfEquations[inx]) 
        {
            case '+':
                this.equationResult += (+this.componentsOfEquations[inx+1]);
                break;
            case '-':
                this.equationResult -= (+this.componentsOfEquations[inx+1]);
                break;
            case '÷':
                this.equationResult /= (+this.componentsOfEquations[inx+1]);
                break;
            case 'x':
                this.equationResult *= (+this.componentsOfEquations[inx+1]);
                break;
        }
        this.runningTotal[inx+1] = this.equationResult;

        if (this.equationResult % 1 != 0 || this.equationResult <= 0) {
            return false;
        }
        if (this.equationResult.toString().indexOf('.') != -1) {
            return false;
        }

        //  Don't allow "÷ 1" to appear in the equation
        if (this.componentsOfEquations[inx] == '÷' && this.componentsOfEquations[inx+1] == '1' ) {
            return false;
        }
        if (this.componentsOfEquations[inx] == 'x' && this.componentsOfEquations[inx+1] == '1' ) {
            return false;
        }

        //  For the easier levels, make sure the result is 30 or below
        if (retries < this.MAX_RETRIES/2) {
            if (this.levelNumber < 10 && this.equationResult > 30) {
                return false;
            }
            if (this.equationResult > 200) {
                return false;
            }
        }
    }

    return true;
  }

  splatDivisionInArray() {
      //  Try to "safely" put a division within the equation, but make sure it makes sense.
      console.log(JSON.stringify(this.runningTotal));

      let random1 = 2 + this.random(8);
      let random2 = 2 + this.random(8);
      let division = random1 * random2;
      console.log('Chosen division of ' + division);
      let factor = this.factorsOfNumber(division);

      let numOfSlots = (this.componentsOfEquations.length-3) / 2;       // 7 => 2
      let splatPlace = 1 + this.random(numOfSlots) * 2;                 // 1, 3, 5

      this.componentsOfEquations[splatPlace] = '÷';
      this.componentsOfEquations[splatPlace+1] = ''+factor;

      if (splatPlace == 1) {
          this.componentsOfEquations[0] = ''+ division;
      } else {
          this.componentsOfEquations[splatPlace-2] = (this.runningTotal[splatPlace-3] < division) ? '+' : '-';
          this.componentsOfEquations[splatPlace-1] = ''+Math.abs(this.runningTotal[splatPlace-3] - division);
      }

      //  TODO:  After splatting this, we need to recalculate the "runningTotals" and overallTotal
  }

  random(max): number {
      //  Choose a random number between 0 and max
      return Math.floor(Math.random() * max);
  }

  showAnswer() {
      this.bShowAnswer = true;
      console.log(this.equation);
  }
}

enum operatorType {
    '+' = 0,
    '-',
    'x',
    '÷'
}
