// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  accountName : "Mikegledhill",
  containerName: "",
  key2: "7jaVKjQiPkfmdYsU/kVc+pQcpR+wYPDy4KozIsDjYfwV+b+tlBv0rwsg2Y85Wu4MkfSMNUyDde0S7h/c1P4JBQ==",
  key: "?sv=2020-02-10&ss=bfqt&srt=sco&sp=rwdlacuptfx&se=2022-05-20T14:09:27Z&st=2021-05-20T06:09:27Z&spr=https,http&sig=TWKv3upNus2xwbUh6pxct8xeyccPrasp7A0ZHZCv%2FHw%3D"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
