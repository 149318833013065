import { Component, OnInit, Directive } from '@angular/core';
import { default as dict } from "../../../assets/dictionary.json";

@Component({
  selector: 'app-pangrams-solver',
  templateUrl: './pangrams-solver.component.html',
  styleUrls: ['./pangrams-solver.component.scss']
})
export class PangramsSolverComponent implements OnInit {

  constructor() { }
  pangramText = 'TYIAUML';
  minLength = 4;
  results = '';
  error = '';
  mainResult = '';
  numOfMatches = 0;

  hexagons = [
    { x:37, y: 0, background: 'lightBlue', text: 'lightBlueText' }, 
    { x:111, y: 0, background: 'lightBlue', text: 'lightBlueText'  }, 
    { x:0, y: 62, background: 'lightBlue', text: 'lightBlueText'  }, 
    { x:74, y: 62, background: 'darkBlue', text: 'darkBlueText'  }, 
    { x:146, y: 62, background: 'lightBlue', text: 'lightBlueText'  }, 
    { x:37, y: 123, background: 'lightBlue', text: 'lightBlueText'  }, 
    { x:111, y: 123, background: 'lightBlue', text: 'lightBlueText'  }, 
  ];
  hexagon = [
    { x: 31, y: 3 },
    { x: 61, y: 19 },
    { x: 61, y: 56 },
    { x: 31, y: 72 },
    { x: 1, y: 56 },
    { x: 1, y: 19 },
    { x: 31, y: 3 }
  ];

  onTextChange(event) {
      this.error = '';
      this.pangramText = event.toUpperCase();
      if (this.pangramText.length >= 7) {
          if (this.pangramText.length > 7) {
              this.error = 'Please only enter 7 letters.  My head gets confused otherwise.';
              return;
          }
          // if (!/[^A-Z]/.test(this.pangramText)) {
          //   this.error = 'You must just type in letters (A-Z).';
          //   return;
          // }
          if (this.countUnique(this.pangramText) != 7) {
              this.error = 'The 7 letters must be unique.';
              return;
          }
          this.search();
      } else {
          this.mainResult = '';
          this.results = '';
      }
  }

  getPoints(offset) {
    //  Create the path for a Hexagon shape, eg: "130 2, 164 19, 164 56, 130 73, 101 56, 101 19, 130 2"
    let path = this.hexagon.map(s => ((s.x) + offset.x) + ' ' + (offset.y + (s.y))).join(', ');
    return path;
  }

  ngOnInit(): void {
      setTimeout(() => {
          this.search();
      }, 500);
  }

  countUnique(str) {
      //  How many unique characters are there in this string ?
      return new Set(str).size;
  }

  search() {
      //  Entry: 
      //    "this.pangramText" is the string the user has typed into the textbox
      //  Exit:
      //    "this.mainResult" is a string listing all possible words which contain ALL SEVEN of the letters
      //    "this.results" is a string listing all possible words

      //  Split our search string into an array of characters ["h", "e", "l", "l", "o"]
      let lettersInSearchString = this.pangramText.split('');

      //  Get a list of dictionary words which are X characters and longer
      let allWords = dict.dict.filter(word => word.length >= this.minLength );

      //  Each word MUST contain the middle letter
      allWords = allWords.filter(word => word.indexOf(lettersInSearchString[3]) > -1);

      //  Now repeatedly go through the array, discarding words whose n'th character isn't one of the
      //  characters in our search string
      for (var i = 0; i < 30; i++) {
          allWords = allWords.filter(s => s.length <= i || lettersInSearchString.includes(s[i]));
      }

      //  Sort our list of words, to have the longest words first
      allWords.sort((a, b) => {
          return b.length - a.length;
      })

      //  Remove any duplicated words from the results
      allWords = allWords.filter((elem, index, self) => index === self.indexOf(elem));

      //  Do any of our words contain ALL of the 7 letters ?
      let pangrams = allWords.filter(elem => this.countUnique(elem) == 7);

      //  If we did find some Pangram words, remove them from our main list of words
      allWords = allWords.filter(elem => !pangrams.includes(elem));

      //  Set the "mainResult" to a comma-separated list of all of the Pangram words
      this.mainResult = pangrams.join(', ');

      //  And return the other results as a comma-separated list of words
      this.results = allWords.join(', ');
      this.numOfMatches = allWords.length;
    }

    //  Taken from: https://stackoverflow.com/a/12646864/391605
    shuffleArray(array) {
      for (var i = array.length - 1; i > 0; i--) {
          var j = Math.floor(Math.random() * (i + 1));
          var temp = array[i];
          array[i] = array[j];
          array[j] = temp;
      }
      return array;
    }

    onGenerate() {
      //  Find all the words which contain just 7 unique letters
      var Regex='/^[a-zA-Z]+$/';
      let allWords = dict.dict.filter(elem => elem.length >= 10 && this.countUnique(elem) == 7);

      //  Pick a random word from the list
      const randomElement = allWords[Math.floor(Math.random() * allWords.length)];

      //  What are the 7 letters in this word ?
      const lettersInWord = Array.from(new Set(randomElement));

      //  Shuffle the 7 letters, then combine them into a 7-letter string
      this.pangramText = this.shuffleArray(lettersInWord).join('');

      //  Finally, get our Search() to find all the words in this 7-letter combination
      this.search();
  }
}
