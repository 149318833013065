import { Component, OnInit, Directive } from '@angular/core';
import { dict } from "../../../assets/dictionary.json";

@Component({
  selector: 'app-wordle-solver',
  templateUrl: './wordle-solver.component.html',
  styleUrls: ['./wordle-solver.component.scss']
})
export class WordleSolverComponent implements OnInit {

  constructor() { }
  wordleText = 'TYIAUML';
  error = '';
  
  ngOnInit(): void {
  }

  onTextChange(event) {
    this.error = '';
    this.wordleText = event.toUpperCase();
  }

}
