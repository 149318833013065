<div class="helpScreensWrapper helpScale" *ngIf="bShowHelp">
    <div class="helpscreens" [ngClass]="whichHelpScreen">
        <div class="side front" style="padding-top:50px;">
            <img src="../../../assets/WordleGenerator.png" width=320> 
            <p style="font-size:28px;margin:0px 0px 60px 0px;">By Mike Gledhill</p>
            <p style="margin-bottom: 0px">Press <img src="../../../assets/CloseIcon.png" width="22"> to play !</p>
            <p>Press <img src="../../../assets/RightArrow2.png" width="22"> for instructions</p>
        </div>
        <div class="side top">
            <div class="PageNumber">1</div>
            <p style="margin-top:40px;">We will think of a 5-letter <br />"secret-word", and you simply<br />have to guess it.</p>
            <p>You type in your own 5-letter words, press ENTER, then the tiles will change color to tell you if each <br />letter
               of your word is also in the secret-word.
            </p>
        </div>
        <div class="side left">
            <div class="PageNumber">2</div>
            <p style="margin-top:40px;">When a letter <u>is</u> in the secret-word, and is in the same place as in your word, it will turn green.
            </p>
            <img src="../../../assets/ExampleWord.png" >
            <p>So, in this example, you know the secret-word starts with an F.</p>
        </div>    
        <div class="side back">
            <div class="PageNumber">3</div>
            <p style="margin-top:40px;">When a letter is in the secret-word, but in a different place, it will turn yellow.
            </p>
            <img src="../../../assets/ExampleWord.png" >
            <p>So, here, you can see that the<br />secret-word contains a L somewhere within it.</p>
        </div>  
        <div class="side right">
            <div class="PageNumber">4</div>
            <p style="margin-top:60px;">If a letter is in the secret-word, in the same position that you've guessed <u>and</u> it's somewhere else in the word, it will show in green and yellow.
            </p>
            <img src="../../../assets/ExampleS.png" >
        </div>  
        <div class="side bottom">
            <div class="PageNumber">5</div>
            <img style="margin-top:15px;" src="../../../assets/ExampleWord.png" >
            <p>So, in this example, the secret-word:</p>
            <ul style="text-align: left;margin-left: 20px;">
                <li>Must start with an F</li>
                <li>Doesn't contain an I or an E</li>
                <li>Contains an L somewhere</li>
                <li>Contains at least 2 S's</li>
                <li>Ends with an S</li>
            </ul>
            <p>eg FLOSS</p>
        </div>  
    </div>
    <div class="helpScreensButtons">
        <div class="button"><img src="../../../assets/LeftArrow2.png" width="50" (click)="prevHelpPage()"></div>
        <div class="button"><img src="../../../assets/CloseIcon.png" width="50" (click)="closeHelp()"></div>
        <div class="button"><img src="../../../assets/RightArrow2.png" width="50" (click)="nextHelpPage()"></div>
    </div>
</div>

<div class="cssFloatLeft button" (click)="onHome()" title="Home" style="z-index:5000" >
    <img src="../../../assets/Home.png"  >
</div>
<div class="cssFloatRightHelp button" (click)="onShowHelp()" title="Help" >
    <img src="../../../assets/Help.png" >
</div>
<div class="cssFloatRight button" (click)="onSurrender()" title="Give up">
    <img src="../../../assets/Surrender.png"  >
</div>
<div class="fullScreen" *ngIf="bGameOver || bHaveWon">
    <div *ngIf="bGameOver">Game over</div>
    <div *ngIf="bHaveWon">Well done !</div>
    <br />
    The word was:<br />
    <div class="solution">
        <div *ngFor="let inx of [0,1,2,3,4]" class="oneGridCell cssOneBox cellSize" 
                    [ngClass]="isLetterInSolution(inx)" 
                    [style.width]="cellWidth"
                    [style.line-height]="lineHeight"
                    [style.font-size]="fontSize"
                    >
                    {{ wordToGuess[inx] }}
        </div>
    </div>
    <!-- <div class="chosenWord">
        {{ wordToGuess }}
    </div> -->
    <div class="button" (click)="startGame()">
        <img src="../../../assets/Refresh.png" width=40 >
    </div>
</div>
<div class="cssScreenSize">
    {{ screenSize }}
</div>
<div class="cssBackgroundGradient" style="display: flex;flex-flow: column;height:100%;">
    <div class="headerHeight" style="height:140px;text-align:center;padding-top:20px;">
        <!--  Header bar -->
        <img src="../../../assets/WordleGenerator.png" width="230" >
    </div>
    <div style="margin:auto auto;flex:1;width:100%;display:flex;flex-direction: column;justify-content: center;overflow:hidden;" #gridArea >
        <!--  This is the main playing area -->
        <div class="oneGridRow" *ngFor="let guess of sixGuesses; index as i" style="width:auto;" >
            <!-- <div class="oneGridCell cellSize" [style.width]="cellWidth">Box 1</div>
            <div class="oneGridCell cellSize" [style.width]="cellWidth">Box 2</div>
            <div class="oneGridCell cellSize" [style.width]="cellWidth">Box 3</div>
            <div class="oneGridCell cellSize" [style.width]="cellWidth">Box 4</div>
            <div class="oneGridCell cellSize" [style.width]="cellWidth">Box 5</div> -->
            <div *ngFor="let inx of [0,1,2,3,4]" class="oneGridCell cssOneBox cellSize" 
                [ngClass]="isLetterInWord(i, guess, inx)" 
                [style.width]="cellWidth"
                [style.line-height]="lineHeight"
                [style.font-size]="fontSize"
                >
                {{ getLetterFromGuess(guess, inx) }}
            </div> 
        </div>
    </div>
    <div class="footerHeight" >
        <!--  This is the keyboard area -->
        <div style="margin:20px auto 0px auto;text-align:center;">
            <div style="border:1px solid #333;border-radius:2px;background-color:rgba(0,0,0,0.35);">
            <table class="keyboardPadding">
                <tr>
                    <td>
                        <div *ngFor="let subLetters of letters" >
                            <div *ngFor="let letter of subLetters"  (click)="onClickedLetter(letter)" 
                                [ngClass]="haveGuessedLetter(letter)" class="button oneLetter oneKeyboardLetter">{{ letter }}
                            </div>
                            <br />
                        </div>
                    </td>
                    <td style="width:8px"></td>
                    <td valign="top">
                        <div (click)="onClickedLetter('Delete')" class="oneLetter cssDelete">
                            <img src="../../../assets/Backspace.png" width=16 >
                        </div>
                        <br />
                        <div (click)="onClickedLetter('Enter')" class="oneLetter cssEnter">
                            <img src="../../../assets/Enter.png" width=16 >
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        </div>
    </div>
</div>



