<!-- <div style="text-align:center">
    <img class="button" src="assets/PangramsSolver.png" width="240" (click)="onJumpToRoute('/pangrams')" >
    <img class="button" src="assets/WordzeeSolver.png" width="240" (click)="onJumpToRoute('/wordzee')" >
    <img class="button" src="assets/WordleGenerator.png" width="240" (click)="onJumpToRoute('/wordle')" >
</div>


 -->
 <div class="fullScreen"></div>
 <div class="cssCenter">
    <div class="cssLogo">
        <div class="logoFont">
            <div class="oneChar letter01">i</div>
            <div [(ngClass)]="addedClassS" class="oneChar letter02">S</div>
            <div class="oneChar letter03">o</div>
            <div class="oneChar letter04">m</div>
            <div class="oneChar letter05">e</div>
            <div [(ngClass)]="addedClassW" class="oneChar letter06">w</div>
            <div [(ngClass)]="addedClassH" class="oneChar letter07">h</div>
            <div class="oneChar letter08">e</div>
            <div class="oneChar letter09">r</div>
            <div class="oneChar letter10">e</div>
            <div class="oneChar dash">_</div>
        </div>
    </div>
</div>
<div class="footer">
    This domain is now for sale.  <a href="https://auctions.godaddy.com/beta?query=isomewhere" _target="blank">Click here</a> for details.
</div>